import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>NOVETAT PER ALUMNES VIP</strong>
                </p>

                <p>
                    El <strong>Divendres 14 de febrer</strong> volem posar-te a prova amb un
                    <strong>simulacre PRE-OFICIAL</strong>.
                </p>

                <p>Una última empenta, un últim esforç per estar ben segurs de donar-ho tot.</p>

                <p>
                    <strong>LAST CALL, ÚLTIMA HORA, FI DE CAMÍ...</strong> digues-li com vulguis,
                    però ara sí que no hi ha més marge. Hem preparat un
                    <strong>simulacre de temari i psicotècnics de MOLT NIVELL</strong> perquè el dia
                    abans puguis posar-te a prova i repassar conceptes clau.
                </p>

                <p>
                    De <strong>18:30 a 20:00</strong>, de manera presencial i amb la presència de
                    <strong>docents especialistes</strong> en temari i psicotècnics, viuràs la
                    sensació que tindràs l'endemà.
                </p>

                <p>
                    Una última oportunitat abans de la prova de la veritat. Situació{" "}
                    <strong>100% real d'examen</strong>. Repàs de dubtes en finalitzar la sessió.
                </p>

                <p>
                    Per només <strong>39,99€!</strong> Apunta’t <strong>ARA!</strong> Places
                    limitades.
                </p>

                <p>
                    <strong>C/ Marti Codolar 18 - Hospitalet (Barcelona)</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="L'última empenta abans del gran dia! Hem preparat un simulacre presencial de temari i psicotècnics de MOLT NIVELL perquè posis a prova els teus coneixements i repassis conceptes clau."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
